<template>
	<div>
		<b-modal :id="modalId"
			@close="closeModal"
			no-close-on-backdrop
			no-close-on-esc>
			<template #modal-title>
				Configure Vehicle Capacity
			</template>
			<div class="py-2">

				<div v-if="loading && isEditMode">
					<div class="d-flex flex-column justify-content-center align-items-center">
						<div class="spinner-border" role="status"></div>
						<p class="text-center mt-4">Saving changes</p>
					</div>
				</div>
				<template v-else>
					<div v-if="verifyingUpdate">
						<div class="d-flex flex-column justify-content-center align-items-center">
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-4">Verifying capacity change</p>
						</div>
					</div>

					<div class="form mb-3" v-else>
						<template v-if="verificationPromptEnabled">
							<div class="alert alert-info d-flex align-items-center" role="alert">
								<img class="mr-2" src="@/assets/img/caution.svg">
								<p class="mb-0">It’s important you read the message below!</p>
							</div>

							<p class="mt-3 mb-3">{{verificationMessage}}</p>
							<p class="mb-3"><span class="cursor-pointer action-link font-sm font-weight-bold" @click="verificationPromptEnabled = false">Edit change</span></p>
						</template>

						<template v-else>
							<div class="form-group mb-3">
								<label class="form-label">Vehicle Type</label>
								<select class="form-control" v-model="form.vehicleType">
									<option :value="null">Select</option>
									<option v-for="(option, index) in vehicleTypes"
										:value="option.name"
										:key="index">{{option.name}}</option>
								</select>
								<div class="invalid-feedback" v-if="$v.form.vehicleType.$dirty && !$v.form.vehicleType.required">
									Please select a vehicle type.
								</div>
							</div>
							<div class="form-group">

								<label class="form-label">Number of seats</label>
								<input v-model="form.seats" class="form-control mb-3" type="number" min="0">
								<div class="invalid-feedback" v-if="$v.form.seats.$dirty && !$v.form.seats.required">
									Please provide a valid number of seats.
								</div>
								<div class="invalid-feedback" v-if="$v.form.seats.$dirty && !$v.form.seats.minValue">
									You have to enter a minimum of 2 seats
								</div>
							</div>
						</template>
					</div>
				</template>

			</div>
			<template #modal-footer>
				<div class="w-100">
					<b-button
						:disabled="loading || verifyingUpdate"
						variant="primary"
						class="float-right"
						@click="proceed()">
						{{ loading || verifyingUpdate ? 'Processing' : verificationPromptEnabled ? 'Yes' : 'Save' }}
					</b-button>
					<b-button
						:disabled="loading"
						variant="secondary"
						class="float-right mr-2"
						@click="closeModal()">
						{{  verificationPromptEnabled ? 'No' : 'Close' }}
					</b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import required from 'vuelidate/lib/validators/required'
import minValue from 'vuelidate/lib/validators/minValue'
import { validationMixin } from 'vuelidate'
import { extractErrorMessage } from '@/utils/helpers'

export default {
  name: 'VehicleCapacityModal',
  mixins: [validationMixin],
  props: {
    show: Boolean,
    model: Object,
    corporateId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      form: {
        vehicleType: null,
        seats: null,
        corporateId: null
      },
      modalId: 'corporate-vehicle-capacity-modal',
      vehicleTypes: [],
      loading: false,
      verifyingUpdate: false,
      previousCapacity: null,
      verificationPromptEnabled: false,
      verificationData: null
    }
  },
  validations: {
    form: {
      seats: {
        required,
        ...{ minValue: () => minValue(2) }
      },
      vehicleType: {
        required
      }
    }
  },
  watch: {
    show: {
      handler: function (value) {
        if (value) {
          this.openModal()
        } else {
          this.closeModal()
        }
      }
    }
  },
  computed: {
    isEditMode () {
      return !!this.model?.id
    },
    verificationMessage () {
      if (this.verificationData && this.verificationData.route) {
        const { count, trip_date, route } = this.verificationData
        const { route_code, pickup, destination } = route
        return `${count} ${count > 1 ? 'people have' : 'person has'}` +
            ` booked on ${route_code} (${pickup} to ${destination}) for this date ${trip_date}.` +
            ' The route will not be affected by this change. Do you want to proceed?'
      } else {
        return 'Are you sure you want to proceed ? There may be trips affected by this capacity change.'
      }
    },
    reducedCapacity () {
      return this.isEditMode ? this.previousCapacity > this.form.seats : false
    }
  },
  created () {
    this.fetchVehicleTypes()
  },
  methods: {
    fetchVehicleTypes () {
      this.axios.get('/v1/vehicle-types').then((res) => {
        this.vehicleTypes = res.data.data
      })
    },
    patchForm (data) {
      if (data) {
        this.form.id = data?.id
        this.form.seats = data?.seats
        this.form.vehicleType = data?.vehicle_type
        this.form.corporateId = data?.corporate_id
      } else {
        this.resetForm()
      }
    },
    resetForm () {
      this.form.id = null
      this.form.seats = null
      this.form.vehicleType = null
      this.form.corporateId = null
      this.$v.form.$reset()
      this.verificationPromptEnabled = false
      this.verificationData = null
    },
    openModal () {
      this.$bvModal.show(this.modalId)
      if (this.model) {
        this.patchForm(this.model)
        this.previousCapacity = this.model.seats || 0
      }
    },
    closeModal () {
      this.$bvModal.hide(this.modalId)
      this.$emit('close')
      this.resetForm()
    },
    async verifyCapacityChange () {
      if (this.isEditMode && this.reducedCapacity) {
        this.verifyingUpdate = true
        try {
          const res = await this.axios.get(`/v1/corporates/${this.corporateId}/vehicleTypes/${this.form.vehicleType}`)
          this.verificationData = res.data
          return this.verificationData.count > 0
        } catch (e) {
          const errMsg = extractErrorMessage(e)
          if (e?.response?.status === 404) {
            return false
          } else {
            this.$toastr.e(errMsg, 'Failed to verify capacity change!')
            return true
          }
        } finally {
          this.verifyingUpdate = false
        }
      } else {
        return false
      }
    },
    async proceed () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      const proceed = await this.verifyCapacityChange()
      if (proceed) {
        this.verificationPromptEnabled = true
      } else {
        this.saveCapacityConfig()
      }
    },
    saveCapacityConfig () {
      const payload = {
        vehicle_type: this.form.vehicleType,
        seats: this.form.seats
      }
      if (this.form.id) {
        payload.id = this.form.id
      }
      this.loading = true
      this.axios.post(`/v1/corporates/${this.corporateId}/vehicle-capacities`, payload).then(() => {
        this.$emit('config-saved')
        this.closeModal()
      }).catch((e) => {
        const errMsg = extractErrorMessage(e)
        this.$toastr.e(errMsg, 'Failed to save configuration!')
      }).finally(() => {
        this.loading = false
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.invalid-feedback {
  display: block;
}

.alert-info {
  $purple-200: #EBEBFF;
  $purple-800: #2323F0;
  background: $purple-200;
  color: $purple-800;
  border: none;
}

.action-link {
  &:hover {
    color: #0DAC5C !important;
  }
}
</style>
