<template>
	<div>
		<div class="card">
			<div class="card-header">
				<div class="col">

				</div>
				<div class="col-auto px-0">
					<button v-if="userHasPermission('corporates009')" @click="openVehicleCapacityModal()" class="btn btn-primary">
						New Configuration
					</button>
				</div>
			</div>
			<div class="card-body p-0">
				<b-table striped hover selectable responsive :items="tableData" :fields="columns" :current-page="currentPage"
					:busy="loading" :empty-text="'No data available.'" show-empty>
					<template #empty="scope">
						<h4 class="text-center">{{ scope.emptyText }}</h4>
					</template>

					<template #table-busy>
						<div class="d-flex flex-column justify-content-center align-items-center">
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-4"><strong>Loading...</strong></p>
						</div>
					</template>

					<template #cell(vehicle_type)="data">
						{{ data.item.vehicle_type }}
					</template>
					<template #cell(seats)="data">
						{{ data.item.seats }}
					</template>

					<template #cell(action)="data" v-if="userHasPermission('corporates010')">
						<button class="btn btn-outline-primary btn-sm mr-2" @click="openVehicleCapacityModal(data.item)">
							Edit
						</button>

						<button class="btn btn-outline-primary btn-sm" @click="deleteCapacityConfig(data.item)">
							Delete
						</button>
					</template>
					<template #cell(action)="data" v-else>
						<button class="btn btn-outline-primary btn-sm">
							unauthorised
						</button>
					</template>
				</b-table>
			</div>
			<div class="card-footer" v-if="totalRecords">
				<b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
			</div>
		</div>
		<vehicle-capacity-modal @close="showSetupModal = false" @config-saved="loadConfigurations" :corporate-id="companyId"
			:model="selectedConfig" :show="showSetupModal">
		</vehicle-capacity-modal>
	</div>
</template>

<script setup>
import Swal from 'sweetalert2'
import { userHasPermission } from '@/composables/core/permissions'
import VehicleCapacityModal from '@/views/Company/pages/VehicleCapacityConfig/VehicleCapacityModal'
import { extractErrorMessage } from '@/utils/helpers'
import { computed, ref, defineProps } from 'vue'
import { axiosInstance } from '@/plugins/axios'

const props = defineProps({
  companyId: {
    required: true,
    type: [Number, String]
  }
})

const company = ref(null)
const loading = ref(false)
const tableData = ref([])
const perPage = ref(20)
const currentPage = ref(1)
const totalRecords = ref(0)
const selectedConfig = ref(null)
const showSetupModal = ref(false)
const columns = ref([
  {
    key: 'vehicle_type',
    label: 'Vehicle Type'
  },
  {
    key: 'seats',
    label: 'Seats'
  },
  {
    key: 'action',
    label: 'Actions'
  }
])
const vehicleTypes = ref([])

const corporateName = computed(() => { return company?.value?.corporate_name || '' })

const init = async () => {
  loading.value = true
  if (!company.value) {
    try {
      await fetchCompany()
      loadConfigurations()
    } catch (e) {
      loading.value = false
    }
  } else {
    loadConfigurations()
  }
}

init()

const loadConfigurations = () => {
  loading.value = true
  const params = { metadata: true }
  axiosInstance.get(`/v1/corporates/${props.companyId}/vehicle-capacities`, { params }).then((res) => {
    tableData.value = res.data.data
    totalRecords.value = res?.data?.metadata?.total
  }).finally(() => {
    loading.value = false
  })
}

const fetchCompany = async () => {
  const res = await axiosInstance.get(`/v1/corporates/${props.companyId}`)
  company.value = res.data
}

const deleteCapacityConfig = (obj1) => {
  Swal.fire({
    icon: 'question',
    title: 'Please Confirm',
    text: 'Are you sure you want to delete this vehicle capacity configuration?',
    showConfirmButton: true,
    showCancelButton: true,
    preConfirm: async () => {
      try {
        return await axiosInstance
          .delete(`/v1/corporates-vehicle-capacities/${obj1.id}`)
      } catch (error) {
        const msg = extractErrorMessage(error, 'An error occurred please try again.')
        Swal.fire().showValidationMessage(msg)
      }
    },
    allowOutsideClick: () => !Swal.isLoading()
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Vehicle capacity configuration has been deleted successfully',
        showCloseButton: true
      }).then(() => {
        loadConfigurations()
      })
    }
  })
}

const openVehicleCapacityModal = (data = null) => {
  selectedConfig.value = data
  showSetupModal.value = true
}
</script>

<style lang="scss" scoped>
//TODO: Apply styling to all tables based on design guide
.table-responsive {
  margin-bottom: 0;
}

::v-deep {
  .b-table {
    & thead {
      tr>th {
        background: transparent;
      }
    }
  }
}

.b-pagination {
  margin-bottom: 0;
}
</style>
